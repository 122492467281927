
import type pocketbaseEs from "pocketbase";
import PocketBase, { RecordAuthResponse } from "pocketbase";

export interface CardJson {
  id: string;
  description: string;
  power: number;
  title: string;
  icon: string;
}

export interface CharacterJson {
	id: string;
	name: string;
	onhand?: CardJson[];
	offhand?: CardJson[];
	/**File name from pocketbase API*/
	icon: string;
  /**UserJson id*/
  owner: string;
  deck?: string[];
}

export interface UserJson {
  id: string;
  username: string;
  /**filename*/
  avatar: string;
  /**display name*/
  name: string;
}

export interface OptsCreateUser {
  username: string;
  password: string;
  passwordConfirm: string;
  name?: string;
}

export const db = {
  url: undefined as string,
  ctx: undefined as pocketbaseEs,
  port: undefined as string,

  init () {
    if (db.ctx) return;
    db.port = window.location.port;

    db.port = window.location.port; //DEV

    db.url = `${window.location.protocol}//${window.location.hostname}:${db.port}`;
    db.url = `https://krystopoeia.net`;
    db.ctx = new PocketBase(db.url);
    db.ctx.autoCancellation(false);
    console.log("Database connect", db.url);
  },
  isLoggedIn () {
    // console.log("checked loggin");
    return db.ctx.authStore.isValid;
  },
  register (username: string, password: string, passwordConfirm: string, dryRun: boolean = false) {
    if (dryRun) {
      return Promise.reject("Running in dry-run mode, database is not connected for development reasons.");
    }
    return db.ctx.collection("users").create({
      password,
      username,
      passwordConfirm
    } as OptsCreateUser);
  },
  login(uname: string, upass: string, dryRun: boolean = false) {
    if (dryRun) {
      return Promise.reject("Running in dry-run mode, database is not connected for development reasons.");
    }
    try {
      const result = db.ctx.collection("users")
      .authWithPassword<UserJson>(uname, upass);
      return result;
    } catch (ex) {
      return Promise.reject(ex);
    }
  },
  logout () {
    db.ctx.authStore.clear();
  }
};

export interface GamesJson {
  id: string;
  name: string;
  /**Character id*/
  owner: string;
  /**Character id*/
  participants: string[];
  /**Card id*/
  banned_cards: string[];
  banned_cards_vote: boolean;
  /**Character join requests*/
  participant_reqs: string[];
  started: boolean;
}
import { h } from 'preact';
import { Link } from 'preact-router/match';
import { db } from '../../db';
import style from './style.css';

const Header = () => (
	<header class={style.header}>
		<a href="/" class={style.logo}>
			<img src="../../assets/icon.svg" alt="Preact Logo" height="32" width="32" />
			<h1>Krystopoeia</h1>
		</a>
		{(db.isLoggedIn() &&
			<nav>
				<Link activeClassName={style.active} href="/">
					Home
				</Link>
				<Link activeClassName={style.active} href="/account">
					Account
				</Link>
				<Link activeClassName={style.active} href="/auth">
					Logout
				</Link>
			</nav>
		) || (
				<nav>
					<Link activeClassName={style.active} href="/">
						Home
					</Link>
					<Link activeClassName={style.active} href="/auth">
						Authenticate
					</Link>
				</nav>
			)}
	</header>
);

export default Header;

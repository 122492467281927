import { h } from 'preact';
import { Route, Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Auth from '../routes/auth';
import About from '../routes/about';
import Account from '../routes/account';

const App = () => (
	<div id="app">
		<Header />
		<main>
			<Router>
				<Route path="/" component={Home} />
				<Route path="/auth/:preFillUsername?/:suggestRegister?" component={Auth} />
				<Route path="/about/" component={About} />
				<Route path="/account/" component={Account} />
			</Router>
		</main>
	</div>
);

export default App;
